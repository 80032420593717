/*----------------------------------------------------------------------------*/

// Required configuration

// This is the main file for configuring Point for your site.
Point.accountName = 'normedia';

// The URL for the server-side proxy
Point.proxyUrl = '/widgit/point';

// If Point.findContentByClass is true, Point content is enabled inside any
// element with any of the classes in Point.pointEnabledDivClass
Point.findContentByClass = true;
Point.pointEnabledDivClass = 'point_sym_hover';

// If Point.findContentById is true, Point content is enabled inside an element
// with an id matching Point.pointEnabledDivId
Point.findContentById = false;
Point.pointEnabledDivId = 'point_content_div';

/*----------------------------------------------------------------------------*/

// Optional configuration

// Whether to use a server-side proxy. Set this to false if using just Flash.
Point.useServerProxy = true;

// Whether to use a Flash proxy. Set this to false if using just a server proxy
// or CORS.
Point.useFlashProxy = true;

// The URL for the Flash proxy. You shouldn't need to change this.
Point.flashProxyUrl = 'https://pointflash.widgit.com/flash_proxy/proxy/' + Point.accountName;

// The size of symbol images in pixels. If this is set to a value other than 80,
// CloudFront cannot be used.
Point.symbolSize = 80;

// The maximum number of symbols that appear in each Point tooltip
Point.maxSymbols = 4;

// If Point.httpsImageUrls is true, Point's symbol images will be served via
// HTTPS rather than HTTP, unless using CloudFront URLs.
Point.httpsImageUrls = true;

// If Point.enableImmediately is set to true, Point will enable itself
// automatically as soon as the page has loaded
Point.enableImmediately = true;

// If set to true, this caches Point tooltips for the current page.
Point.cacheTooltips = true;

// The length of time in milliseconds to keep cached tooltips
Point.cacheExpiry = 86400000;

Point.enabledPointImage = '/point/on.png';
Point.disabledPointImage = '/point/off.png';
Point.waitIconImage = '/point/point_waiticon.gif';

// Point will not be applied to text within the following elements:
Point.blacklistedElements = [
  'applet',
  'button',
  'canvas',
  'embed',
  'iframe',
  'img',
  'input',
  'map',
  'object',
  'script',
  'select',
  'style', 'svg',
  'table',
  'tbody',
  'textarea',
  'tfoot',
  'thead',
  'title',
  'tr'
];

Point.blacklistedWords = [
];

// Point will not be applied to any element or descendant of any element with
// any of the following classes:
Point.blacklistedClasses = [
  'display_cml',
  'display_cml_divs',
  'insite-content',
  'no-point-content'
];

// Multilingual support
Point.defaultLanguage = 'Norwegian';

Point.languageCssClasses = {
  'point_english_uk': 'English_UK',
  'point_czech':      'Czech',
  'point_danish':     'Danish',
  'point_dutch':      'Dutch',
  'point_english_us': 'English_US',
  'point_finnish':    'Finnish',
  'point_french':     'French',
  'point_greek':      'Greek',
  'point_italian':    'Italian',
  'point_norwegian':  'Norwegian',
  'point_polish':     'Polish',
  'point_portuguese': 'Portuguese',
  'point_spanish':    'Spanish',
  'point_swedish':    'Swedish'
};

// The delay between hovering over a word and Point starting to retrieve
// symbols for that word
Point.hoverTimeout = 200;

// Point.spansInheritParentStyles should be set to false unless there are
// any layout problems inside Point-enabled content.
Point.spansInheritParentStyles = false;

// Whether each word is symbolised within the context in which it appears or is
// symbolised on its own
Point.symboliseContext = true;

// Whether idioms and phrases are symbolised as one concept or as individual
// words. Has no effect if Point.symboliseContext is false.
Point.symboliseIdioms = false;

// Whether or not Point always surrounds each word within Point-enabled content
// when the page loads. If false and Point.enableImmediately is false, and the
// user has not previously enabled Point, Point waits until the user enables
// Point before performing "spanification"
Point.spanifyOnLoad = false;

// Whether or not Point removes the elements it has added to the document when
// Point is disabled
Point.despanifyWhenDisabled = true;

// Access key for enabling and disabling Point
Point.accessKey = 'P';

// Whether or not to use a cookie to track whether the user has previously
// enabled Point
Point.useCookie = true;

// Whether to use CORS where available, bypassing the need for a proxy
Point.disableCors = false;

// The URL for the Point server. You shouldn't need to change this.
Point.corsUrl = 'https://point.widgit.com/point.php';

// Skin tone. Use an integer value between 0 and 5 inclusive.
// Only supported for Danish, Dutch, English (all versions), French, Latvian,
// Norwegian, Swedish and Italian
Point.skinTone = 1;
